import react from "react";
import { useEffect, useLayoutEffect  } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation  } from "react-router-dom";
import "./sass/main.scss";

//Pages
import Home from "./pages/home";
import Model from "./pages/SofLuxury";
import Team from "./pages/team";
import Rebuild from "./pages/rebuild";
import Carreer from "./pages/Carreer";
import Header from "./components/Header";
import Services from "./pages/services";
import Vip from "./pages/vip";
import Crypto from "./pages/crypto";

const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 

function App() {

  return (
    <Router>
      <Wrapper>
      <Routes>
        <Route path={`${process.env.PUBLIC_URL}/`} element={<Home />} />
        <Route path={`${process.env.PUBLIC_URL}/5s-of-luxury`} element={<Model />} />
        <Route path={`${process.env.PUBLIC_URL}/team`} element={<Team />} />
        <Route path={`${process.env.PUBLIC_URL}/rebuild-now`} element={<Rebuild />} />
        <Route path={`${process.env.PUBLIC_URL}/vh-experience`} element={<Services />} />
        <Route path={`${process.env.PUBLIC_URL}/vh-vip`} element={<Vip />} />
        <Route path={`${process.env.PUBLIC_URL}/pay-with-cryptocurrency`} element={<Crypto />} />
      </Routes>
      </Wrapper>

      <a
        href={`https://api.whatsapp.com/send?phone=9607498881&text=Hello,`}
        class="whatsapp_float"
        id="whats-app-float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>  

    </Router>
  );
}

export default App;
