import React, { useState, useRef, useEffect } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { useMediaQuery } from 'react-responsive';
import Carousel from 'react-bootstrap/Carousel';
import Footer from "../components/Footer";
import Header from "../components/Header";


const banner = {

  animate: {
    transition: {
      delayChildren: 0,
      staggerChildren: 0.1,
    },
  },
};


const letterAni = {
  initial: { y: 400 },
  animate: {
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1,
    },
  },
};



const Images1 = ({ speed }) => {
  const { scrollYProgress } = useViewportScroll();
  const yValue = useTransform(scrollYProgress, [0, 1], [0, 200 * speed]);

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }} style={{ y: yValue }}>
      <img className="img-100" src={process.env.PUBLIC_URL + `/images/viluxur_vip_fallon_banner.jpg`} />
    </motion.div>
  )
};



const Images3 = ({ speed }) => {
  const { scrollYProgress } = useViewportScroll();
  const yValue = useTransform(scrollYProgress, [0, 1], [0, 200 * speed]);

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }} style={{ y: yValue }}>
      <img className="img-100" src={process.env.PUBLIC_URL + `/images/viluxur_vip_small_2.jpg`} />
    </motion.div>
  )
};



const Ellice = ({ speed }) => {
  const { scrollYProgress } = useViewportScroll();
  const yValue = useTransform(scrollYProgress, [0, 1], [0, 200 * speed]);

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }} style={{ y: yValue }}>
      <img className="img-100" src={process.env.PUBLIC_URL + `/images/Our.jpg`} />
    </motion.div>
  )
};


const Ellice2 = ({ speed }) => {
  const { scrollYProgress } = useViewportScroll();
  const yValue = useTransform(scrollYProgress, [0, 1], [0, 200 * speed]);

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }} style={{ y: yValue }}>
      <img className="img-100" src={process.env.PUBLIC_URL + `/images/xx.jpg`} />
    </motion.div>
  )
};

function Vip() {





  const [playMarquee, setPlayMarquee] = useState(false);

  useEffect(() => {
    setPlayMarquee(true);

  }, []);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })



  return (
    <div>
      <Header />
      {isDesktopOrLaptop &&

        <div className="ve-are-viluxur-top-page">
          {/* <motion.div className="banner  m-xy-s viluxur-m-top-header banner-inner" variants={banner}>
        <BannerRowTop />

        </motion.div> */}

          <div className="transition-image mt-final final we-are-viluxr-img">
            <motion.img src={process.env.PUBLIC_URL + `/images/viluxur_vip_banner_1.jpg`} />
          </div>


          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              ease: "easeInOut",
              duration: 1,
              delay: 0.6,
            }}
            className=""
          >


            <motion.div className="Middle">
              <div className="we-got-you we-are-viluxur">
                <div>


                  <h1 className="title-2 color-white">Luxury and exclusivity</h1>
                  <p className="para color-white">Discover our unique travel packages specially created for demanding travellers in search of ultimate experiences around the Maldives.</p>

                </div>

              </div>

            </motion.div>

          </motion.div>





          <section className="container-fluid mt-8">
            <div className="row">
              <div className="col-12">

                <div className="we-got-you-2">
                  <div>

                    <h2 className="title-2 ">VH VIP  </h2>
                    <p className="para">Delivering excellence at all touchpoints is how we measure our success. This is achieved by curating a comprehensive range of travel services tailored to our clients and partners. From private jets services, to booking the most exotic locations — we cater to the world’s elite names.
                    </p>

                  </div>
                </div>
              </div>
            </div>

          </section>

          <section className="container-fluid ">

            <div className="row alind-end m-min-top-vip">
              <div className="col-7 z-index-min">
                <Images1 speed={0} />

              </div>
              <div className="col-2">

              </div>
              <div className="col-3 ">

                <Images3 speed={0} />

              </div>

            </div>

            <div className="row">
              <div className="col-12">
                <div className="image-container">
                  <img className="img-svg" src={process.env.PUBLIC_URL + `/images/viluxur_VIP.svg`} />
                </div>

              </div>
            </div>
          </section>



          <section className="container-fluid qute-icon-m-top">
            <div className="row">
              <div className="col-12">

                <div className="we-got-you-2 the-end-goal">
                  <div>
                    <span className="quote-icon-gray">
                      <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="0" fill="none" width="24" height="24" /><g><path d="M11.192 15.757c0-.88-.23-1.618-.69-2.217-.326-.412-.768-.683-1.327-.812-.55-.128-1.07-.137-1.54-.028-.16-.95.1-1.956.76-3.022.66-1.065 1.515-1.867 2.558-2.403L9.373 5c-.8.396-1.56.898-2.26 1.505-.71.607-1.34 1.305-1.9 2.094s-.98 1.68-1.25 2.69-.346 2.04-.217 3.1c.168 1.4.62 2.52 1.356 3.35.735.84 1.652 1.26 2.748 1.26.965 0 1.766-.29 2.4-.878.628-.576.94-1.365.94-2.368l.002.003zm9.124 0c0-.88-.23-1.618-.69-2.217-.326-.42-.77-.692-1.327-.817-.56-.124-1.074-.13-1.54-.022-.16-.94.09-1.95.75-3.02.66-1.06 1.514-1.86 2.557-2.4L18.49 5c-.8.396-1.555.898-2.26 1.505-.708.607-1.34 1.305-1.894 2.094-.556.79-.97 1.68-1.24 2.69-.273 1-.345 2.04-.217 3.1.165 1.4.615 2.52 1.35 3.35.732.833 1.646 1.25 2.742 1.25.967 0 1.768-.29 2.402-.876.627-.576.942-1.365.942-2.368v.01z" /></g></svg></span>

                    <p className="para width-700">“Think of us as an exclusive concierge service to the Maldives. We are here to help create the most premier packages with precision, taste and the luxury offered solely in these opulent islands.”
                    </p>
                    <h3 className="name"><span className="font-bembo">Fallon Manampulle </span></h3>
                    <p className="para">Senior Sales Executive</p>
                  </div>
                </div>
              </div>
            </div>

          </section>

          <section className="container-fluid mt-8">
            <div className="row">
              <div className="col-12">

                <div className="we-got-you-2">
                  <div>
                    <h2 className="title-2 ">How Do We Work? </h2>
                    <p className="para ">VH VIP works with a little black book of premium names in the travel industry. Our resort and hotel partners work with us to ensure your clients are handled with tact and precision during their stay. From Butler services to private islands, we guarantee your executive clients are taken care of with the utmost discretion.
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </section>

          <section className="container-fluid margin-top-100">
            <div className="row">
              <div className="col-12">
                <Carousel className="aword-carasol" fade>
                  {/* <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={process.env.PUBLIC_URL + `/images/viluxur_vip_banner_1.jpg`}
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      <div className="we-got-you-2">
                        <div>




                        </div>
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item> */}
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={process.env.PUBLIC_URL + `/images/viluxur_vip_banner_2.jpg`}
                      alt="Second slide"
                    />

                    <Carousel.Caption>
                      <div className="we-got-you-2">
                        <div>

                        </div>
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={process.env.PUBLIC_URL + `/images/viluxur_vip_banner_3.jpg`}
                      alt="Second slide"
                    />

                    <Carousel.Caption>
                      <div className="we-got-you-2">
                        <div>

                        </div>
                      </div>
                    </Carousel.Caption>
                  </Carousel.Item>

                </Carousel>
              </div>
            </div>
          </section>




          <div className="m-xy-s mt-8">
            <div className="row">
              <div className="col-7">

              </div>
              <div className="col-5 ">
                <div className="">
                  <div className="width-600">
                    <h2 className="title-2 ">Our Services </h2>
                    <p className="para">
                      <ul className="our_servis">
                        <li>Discretion On Travel Itinerary </li>
                        <li>Curated Packages With Exclusive Offers On Private Villas and Private Islands in Maldives </li>
                        <li>Booking Of Private Jet  </li>
                        <li>Turn over responses in 30 minutes or less </li>
                        <li>24/7 International Travel Service Line to Maldives  </li>
                        <li>Flexible payment gateways </li>
                        <li>Flexibility on Last Minute Cancellations and Additions to Bookings </li>
                      </ul>
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid mrgn-mins-top-prep">
            <div className="row">
              <div className="col-6 p-0">
                <motion.img className="width-100" src={process.env.PUBLIC_URL + `/images/viluxur_vip_our_services.jpg`} />
              </div>
            </div>
          </div>


          {/* <section className="container-fluid mt-8">
          <div className="row">
          <div className="col-1">
          
          </div>
          <div className="col-4 ">
          <div className="we-got-you-belef">
            <div>
              <h2 className="title-2 ">Our Belief   </h2>
              <p className="para">We believe that relationships matter over a business and we make every effort in strengthening our relationships with you.
              </p>

              <p className="para">We are not conservative, we don’t always play by the rule, but integrity is a core component of our mission and vision.
              </p>
                
            </div>
          </div>
            </div>
            
            <div className="col-7 item-3">
           
         
            
            </div>

          </div>

          <div className="row">
            <div className="col-6">

            </div>
            <div className="col-6 we-are-local-mt">
            <Ellice className="" speed={0}/>
            </div>
        </div>
      </section> */}

          {/* <div className="container">
        <div className="row margin-top-and-botm">
          <div className="col-12 ">
          <div className=" ">
            <div>
              <h2 className="title-2 text-center">Meet The Faces Behind Your Next Holiday   </h2>
              <p className="para text-center">Our team of travel experts are located across the globe with unique experiences and are at the core of our operation as an industry leader. As a company that caters to the whole world, we understand the importance of good communication and having a diverse worldview. 
              </p>     

              <p className="para text-center">This is why our team is multinational and well-exposed to the journeys we guide our customers through. <br/> It is the distinct charm of the Viluxur experience. 
              </p>  
            </div>
          </div>
          </div>
         
        </div>
        <div className="row ">
            <div className="col-4 pdn-50-left-r">
            <img className="width-100" src={process.env.PUBLIC_URL + `/images/meet-team/SHAFFI.jpg`} />
            <div className="content-inner padin-20">
                  <p className="para">Is a jack of all trades so to speak. The Managing Director of Viluxur Group, with experience in travel and tourism having worked in Sri Lanka, Singapore and the Maldives, has an eye for vision. </p>
                  <h3 className="name"><span className="font-bembo">Shafraz ‘Shaffi’ Fazley</span></h3>
               
                </div>
            </div>
            <div className="col-4 pdn-50-left-r">
            <img className="width-100 " src={process.env.PUBLIC_URL + `/images/meet-team/ELLICE.jpg`} />
            <div className="content-inner padin-20">
                  <p className="para">The Chief Operating Officer of Viluxur Group has over a decade of experience in the travel and tourism sectors of the Maldives. Ellice currently heads the sales divisions of Viluxur and OV. </p>
                  <h3 className="name"><span className="font-bembo">Ellice Bugayong</span></h3>
               
                </div>
            </div>
            <div className="col-4 pdn-50-left-r">
            <img className=" width-100" src={process.env.PUBLIC_URL + `/images/meet-team/HYDEE.jpg`} />
            <div className="content-inner padin-20">
                  <p className="para">Haydee is dedicated to the Americas market, taking personal care of all inquiries of the travel trade and with her extensive experience and knowledge of the Maldives after working and living in the destination for over a decade. </p>
                  <h3 className="name "><span className="font-bembo">Haydee </span></h3>
               
                </div>
            </div>
        </div>
        <div className="row mt-8">
            <div className="col-4 pdn-50-left-r">
            <img className="width-100" src={process.env.PUBLIC_URL + `/images/meet-team/ANNA.jpg`} />
            <div className="content-inner padin-20">
                  <p className="para">Anastasia is our voice in Russia. With a majority of our client base in the Russian market, Anastasia ensures our agents have a comprehensive guide to the Maldives by recommending our best selling resorts in the islands </p>
                  <h3 className="name"><span className="font-bembo">Anastasiia</span></h3>
               
                </div>
            </div>
            <div className="col-4 pdn-50-left-r">
            <img className="width-100 " src={process.env.PUBLIC_URL + `/images/meet-team/chamindra.jpg`} />
            <div className="content-inner padin-20">
                  <p className="para">A real numbers man, the organization’s Director of Finance and Strategic Planning, Chamindra holds in his palms the future of Viluxur Group.  </p>
                  <h3 className="name"><span className="font-bembo">Chamindra Gamage</span></h3>
               
                </div>
            </div>
            <div className="col-4 pdn-50-left-r">
            <img className=" width-100" src={process.env.PUBLIC_URL + `/images/meet-team/Nisha.jpg`} />
            <div className="content-inner padin-20">
                  <p className="para">Dr. Abu Bakar’s academic, research and consultancy expertise are in the areas of MICE, Destination marketing, Women in Tourism, Sustainable Tourism, Halal Tourism and Tourism education. </p>
                  <h3 className="name "><span className="font-bembo">Dr. Nisha Abu Bakar </span></h3>
               
                </div>
            </div>
        </div>
    </div>
          
    <section className="container">
    <div className="row margin-top-and-botm">
          <div className="col-12 ">
          <div className=" ">
            <div>
              <h2 className="title-2 text-center">Once a Viluxurian, Always a Viluxurian </h2>
              <p className="para text-center">We believe in close connections and are always happy when our team members move to new aspirations.
              </p>     

              <p className="para text-center">Our former team memebers, too feel a ‘soul connection’ to their once place of employment and <br/> are always ready to share a good work on our behalf. 
              </p>  
            </div>
          </div>
          </div>
         
        </div>
        <div className="row">
        <Carousel>
      <Carousel.Item>
        <img
          className="d-block img-carsol"
          src={process.env.PUBLIC_URL + `/images/Leswith.jpg`}
          alt="First slide"
        />
        <Carousel.Caption>
        <p className="para">I learnt and had my biggest impactful career growth with Viluxur Holidays. I am always honored to have been part of that great adventure. </p>
        <h3 className="name"><span className="font-bembo">Liswith</span></h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block img-carsol"
          src={process.env.PUBLIC_URL + `/images/Jessy.jpg`}
          alt="Second slide"
        />

        <Carousel.Caption>
        <p className="para">Viluxur was not just an organiztion but a family. Our work together in a whole created some of the best trips for our customers, your clients. I think that is what makes the brand unique. They personalize each trip and that is something many claim but few actually achieve.  </p>
        <h3 className="name"><span className="font-bembo">Jessie Jingwen</span></h3>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block img-carsol"
          src={process.env.PUBLIC_URL + `/images/sita.jpg`}
          alt="Second slide"
        />

        <Carousel.Caption>
        <p className="para">Viluxur Holidays and the Maldives are inseparable and essential elements in my life that not only enriched my experiences, especially during the early start of my career in the tourism industry but also opened my horizons as an individual. </p>
        <h3 className="name"><span className="font-bembo">Sita</span></h3>
        </Carousel.Caption>
      </Carousel.Item>
     
    </Carousel>
        </div>

    </section>

    <div className="container-fluid mt-10">
        <div className="row">
              <div className="col-12 p-0">
              <motion.img className="width-100" src={process.env.PUBLIC_URL + `/images/xx.jpg`} />
              </div> 
        </div>     
     </div>
     <div className="m-xy-s">
        <div className="row">
          <div className="col-7 m-m-t-15">
         
          </div>
          <div className="col-5 m-m-t-15">
          <div className="text-section our-bilef">
            <div>
            <h2 className="title-2  color-white">We Are Prepared </h2>
              <p className="para  color-white">At Viluxur Holidays, we believe in fully equipping our teams with the best tools they need to forge this trade. Upskilling our people is one of our priorities to ensure our teams have best equipped and ready to meet our travellers’ needs. 
              </p>

              <p className="para  color-white">
              One such instance was when Viluxur teamed up with Elevated Consultancy Singapore to learn how to network effectively and utilize our individual and team strengths to reach for the stars in the highly competitive global travel space.  
              </p>
            </div>
          </div>
        </div>
        </div>
    </div>
 */}


        </div>
      }

      {isTabletOrMobile &&

        <div className="page-space-top-mobile we-are-viluxur-page">
          <motion.div
            initial={{ opacity: 0, y: 180 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              ease: "easeInOut",
              duration: 1,
              delay: 0.6,
            }}
            className=""
          >


            <div className="transition-image mt-final final">
              <motion.img src={process.env.PUBLIC_URL + `/images/viluxur_vip_banner_top.jpg`} />
            </div>

            <div className="we-got-you">
              <div>

                <h1 className="title-2">Luxury and exclusivity</h1>
                <p className="para ">Discover our unique travel packages specially created for demanding travellers in search of ultimate experiences around the Maldives.</p>
              </div>
            </div>

            <section className="container-fluid ">

              <div className="row alind-end mrgn-mins-top ">
                <div className="col-5 z-index-min">


                </div>

                <div className="col-7 ">

                  <Images3 speed={0} />

                </div>

              </div>

              <div className="row">
                <div className="col-5">

                </div>
                <div className="col-7">
                  <div className="image-container">
                    <img className="img-svg" src={process.env.PUBLIC_URL + `/images/viluxur_VIP.svg`} />
                  </div>

                </div>
              </div>
            </section>


            <section className="container-fluid ">

              <div className="row row-top-min-3">

                <div className="col-12">
                  <div className="we-got-you-4">
                    <div>
                      <h2 className="title-2 ">VH VIP  </h2>
                      <p className="para ">Delivering excellence at all touchpoints is how we measure our success. This is achieved by curating a comprehensive range of travel services tailored to our clients and partners. From private jets services, to booking the most exotic locations — we cater to the world’s elite names.
                      </p>


                    </div>
                  </div>
                </div>

              </div>

            </section>

            <section className="container-fluid margin-min-and-zindex mt-12">
              <div className="row">
                <div className="col-12 zindex-min">
                  <Images1 speed={0} />
                </div>
              </div>
            </section>


            <section className="container-fluid">
              <div className="row">
                <div>
                  <div className="we-got-you-2 ">
                    <div>
                      <span className="quote-icon-gray">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="0" fill="none" width="24" height="24" /><g><path d="M11.192 15.757c0-.88-.23-1.618-.69-2.217-.326-.412-.768-.683-1.327-.812-.55-.128-1.07-.137-1.54-.028-.16-.95.1-1.956.76-3.022.66-1.065 1.515-1.867 2.558-2.403L9.373 5c-.8.396-1.56.898-2.26 1.505-.71.607-1.34 1.305-1.9 2.094s-.98 1.68-1.25 2.69-.346 2.04-.217 3.1c.168 1.4.62 2.52 1.356 3.35.735.84 1.652 1.26 2.748 1.26.965 0 1.766-.29 2.4-.878.628-.576.94-1.365.94-2.368l.002.003zm9.124 0c0-.88-.23-1.618-.69-2.217-.326-.42-.77-.692-1.327-.817-.56-.124-1.074-.13-1.54-.022-.16-.94.09-1.95.75-3.02.66-1.06 1.514-1.86 2.557-2.4L18.49 5c-.8.396-1.555.898-2.26 1.505-.708.607-1.34 1.305-1.894 2.094-.556.79-.97 1.68-1.24 2.69-.273 1-.345 2.04-.217 3.1.165 1.4.615 2.52 1.35 3.35.732.833 1.646 1.25 2.742 1.25.967 0 1.768-.29 2.402-.876.627-.576.942-1.365.942-2.368v.01z" /></g></svg></span>

                      <p className="para">“Think of us as an exclusive concierge service to the Maldives. We are here to help create the most premier packages with precision, taste and the luxury offered solely in these opulent islands.”
                      </p>
                      <h3 className="name"><span className="font-bembo">Fallon Manampulle </span></h3>
                      <p className="para">Senior Sales Executive</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>





            <section className="container-fluid mt-8 min-zindex">
              <div className="row">
                <div className="col-12">
                  <Carousel className="aword-carasol min-zindex" fade>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={process.env.PUBLIC_URL + `/images/viluxur_vip_banner_1.jpg`}
                        alt="First slide"
                      />
                      <Carousel.Caption>
                        <div className="we-got-you-2">
                          <div>

                            <h2 className="title-2 color-white">Our Milestones  </h2>
                            <p className="para color-white">We are proud of all the organization’s achievements as we believe they are the stepping stones to achieving our greatness and leaving a sustainable footprint in the global tourism sector.
                            </p>


                          </div>
                        </div>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={process.env.PUBLIC_URL + `/images/viluxur_vip_banner_2.jpg`}
                        alt="Second slide"
                      />

                      <Carousel.Caption>
                        <div className="we-got-you-2">
                          <div>

                            <h2 className="title-2 color-white">Our Milestones  </h2>
                            <p className="para color-white">We are proud of all the organization’s achievements as we believe they are the stepping stones to achieving our greatness and leaving a sustainable footprint in the global tourism sector.
                            </p>



                          </div>
                        </div>
                      </Carousel.Caption>
                    </Carousel.Item>

                  </Carousel>
                </div>
              </div>
            </section>

            <section className="container-fluid">
              <div className="row">
                <div>
                  <div className="we-got-you-2 ">
                    <div>


                      <h2 className="title-2 ">How Do We Work?  </h2>
                      <p className="para ">VH VIP works with a little black book of premium names in the travel industry. Our resort and hotel partners work with us to ensure your clients are handled with tact and precision during their stay. From Butler services to private islands, we guarantee your executive clients are taken care of with the utmost discretion.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>


            <section className="container-fluid mt-8">
              <div className="row">
                <div className="col-12 zindex-min">
                  <motion.img className="width-100" src={process.env.PUBLIC_URL + `/images/viluxur_vip_our_services.jpg`} />
                </div>
              </div>
            </section>

            <section className="container-fluid">
              <div className="row">
                <div>
                  <div className="we-got-you-2 ">
                    <div>



                      <h2 className="title-2 ">Our Services </h2>
                      <p className="para">
                        <ul className="our_servis">
                          <li>Discretion On Travel Itinerary </li>
                          <li>Curated Packages With Exclusive Offers On Private Villas and Private Islands in Maldives </li>
                          <li>Booking Of Private Jet  </li>
                          <li>Turn over responses in 30 minutes or less </li>
                          <li>24/7 International Travel Service Line to Maldives  </li>
                          <li>Flexible payment gateways </li>
                          <li>Flexibility on Last Minute Cancellations and Additions to Bookings </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="mt-8">

            </section>

            {/* <section>
<div className="row">
        <Carousel>
      <Carousel.Item>
        <img
          className="d-block mobile-slider-img"
          src={process.env.PUBLIC_URL + `/images/meet-team/SHAFFI.jpg`}
          alt="First slide"
        />
        <Carousel.Caption>
        <p className="para">Is a jack of all trades so to speak. The Managing Director of Viluxur Group, with experience in travel and tourism having worked in Sri Lanka, Singapore and the Maldives, has an eye for vision. </p>
                  <h3 className="name"><span className="font-bembo">Shafraz ‘Shaffi’ Fazley</span></h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block mobile-slider-img"
          src={process.env.PUBLIC_URL + `/images/meet-team/ELLICE.jpg`}
          alt="Second slide"
        />

        <Carousel.Caption>
        <p className="para">The Chief Operating Officer of Viluxur Group has over a decade of experience in the travel and tourism sectors of the Maldives. Ellice currently heads the sales divisions of Viluxur and OV. </p>
                  <h3 className="name"><span className="font-bembo">Ellice Bugayong</span></h3>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block mobile-slider-img"
          src={process.env.PUBLIC_URL + `/images/meet-team/HYDEE.jpg`}
          alt="Second slide"
        />

        <Carousel.Caption>
        <p className="para">Haydee is dedicated to the Americas market, taking personal care of all inquiries of the travel trade and with her extensive experience and knowledge of the Maldives after working and living in the destination for over a decade. </p>
                  <h3 className="name "><span className="font-bembo">Haydee </span></h3>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item>
        <img
          className="d-block mobile-slider-img"
          src={process.env.PUBLIC_URL + `/images/meet-team/ANNA.jpg`}
          alt="Second slide"
        />

        <Carousel.Caption>
        <p className="para">Anastasia is our voice in Russia. With a majority of our client base in the Russian market, Anastasia ensures our agents have a comprehensive guide to the Maldives by recommending our best selling resorts in the islands </p>
                  <h3 className="name"><span className="font-bembo">Anastasiia</span></h3>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item>
        <img
          className="d-block mobile-slider-img"
          src={process.env.PUBLIC_URL + `/images/meet-team/chamindra.jpg`} 
          alt="Second slide"
        />

        <Carousel.Caption>
        <p className="para">A real numbers man, the organization’s Director of Finance and Strategic Planning, Chamindra holds in his palms the future of Viluxur Group.  </p>
                  <h3 className="name"><span className="font-bembo">Chamindra Gamage</span></h3>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block mobile-slider-img"
          src={process.env.PUBLIC_URL + `/images/meet-team/Nisha.jpg`}
          alt="Second slide"
        />

        <Carousel.Caption>
        <p className="para">Dr. Abu Bakar’s academic, research and consultancy expertise are in the areas of MICE, Destination marketing, Women in Tourism, Sustainable Tourism, Halal Tourism and Tourism education. </p>
                  <h3 className="name "><span className="font-bembo">Dr. Nisha Abu Bakar </span></h3>
        </Carousel.Caption>
      </Carousel.Item>



     
    </Carousel>
        </div>

</section> */}

            {/* <section className="container">
    <div className="row margin-top-and-botm">
          <div className="col-12 ">
          <div className=" we-got-you-4-mobile">
            <div>
              <h2 className="title-2 text-center">Once a Viluxurian, Always a Viluxurian </h2>
              <p className="para text-center">We believe in close connections and are always happy when our team members move to new aspirations.
              </p>     

              <p className="para text-center">Our former team memebers, too feel a ‘soul connection’ to their once place of employment and <br/> are always ready to share a good work on our behalf. 
              </p>  
            </div>
          </div>
          </div>
         
        </div>
        <div className="row">
        <Carousel>
        <Carousel.Item>
          <img
            className="d-block img-carsol"
            src={process.env.PUBLIC_URL + `/images/Leswith.jpg`}
            alt="First slide"
          />
          <Carousel.Caption>
          <p className="para">I learnt and had my biggest impactful career growth with Viluxur Holidays. I am always honored to have been part of that great adventure. </p>
          <h3 className="name"><span className="font-bembo">Liswith</span></h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block img-carsol"
            src={process.env.PUBLIC_URL + `/images/Jessy.jpg`}
            alt="Second slide"
          />

          <Carousel.Caption>
          <p className="para">Viluxur was not just an organiztion but a family. Our work together in a whole created some of the best trips for our customers, your clients. I think that is what makes the brand unique. They personalize each trip and that is something many claim but few actually achieve.  </p>
          <h3 className="name"><span className="font-bembo">Jessie Jingwen</span></h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block img-carsol"
          src={process.env.PUBLIC_URL + `/images/sita.jpg`}
          alt="Second slide"
        />

        <Carousel.Caption>
        <p className="para">Viluxur Holidays and the Maldives are inseparable and essential elements in my life that not only enriched my experiences, especially during the early start of my career in the tourism industry but also opened my horizons as an individual. </p>
        <h3 className="name"><span className="font-bembo">Sita</span></h3>
        </Carousel.Caption>
      </Carousel.Item>
       </Carousel>
        </div>
    </section> */}

            {/* <div className="container-fluid mt-10">
        <div className="row">
              <div className="col-12 p-0">
              <motion.img className="width-100" src={process.env.PUBLIC_URL + `/images/xx.jpg`} />
              </div> 
        </div>     
     </div>
     <div className="m-xy-s">
        <div className="row">
          <div className="col-5 m-m-t-15">
          <div className="text-section our-bilef">
            <div>
            <h2 className="title-2 text-center">We Are Prepared </h2>
              <p className="para text-center">At Viluxur Holidays, we believe in fully equipping our teams with the best tools they need to forge this trade. Upskilling our people is one of our priorities to ensure our teams have best equipped and ready to meet our travellers’ needs. 
              </p>

              <p className="para text-center">
One such instance was when Viluxur teamed up with Elevated Consultancy Singapore to learn how to network effectively and utilize our individual and team strengths to reach for the stars in the highly competitive global travel space.

              </p>
            </div>
          </div>
          </div>
          <div className="col-7">
        </div>
        </div>
    </div> */}

          </motion.div>
        </div>


      }



      {/* {isDesktopOrLaptop && 
<section className="container margin-top-and-botm">
          <div className="row">
                <p className="vision">
                Our goal to give<span className="font-bembo">Travellers </span> far and wide seeking the 5 Ss of luxury travel to the <span className="font-bembo">Maldives:</span> Serenity, Sun, Sand, Sophistication and Serendipity.
                </p>
          </div>
      </section>

} */}



      {/* {isTabletOrMobile &&  


<div>
<div className="row row-top-min mobile-row-top">
            <div className="col-12">
            <div className="we-got-you-2-mobile">
            <div>
              <h2 className="title-2">Over a decade of experience </h2>
              <p className="para">Viluxur Holidays is headquartered in Singapore, with a myriad of offices from Sri Lanka to Russia. 
               We have expanded with a global presence in being the most sought after travel club for bespoke and unique holidays for the discerning traveller. 
               </p>

               <p className="para">With over a decade of experience in luxury travel around the Maldives, we have established a strong identity that personifies innovation and personalization. Crafting unique experiences has become a hallmark of Villuxur, one that is enabled by the partners we work with. We believe true hospitality doesn’t just start when someone enters their resorts but instead, from the moment they begin planning their holidays. 
               </p>
                     
            </div>
          </div>
            </div>
            
          </div>

          <div className="row mobile-m-top">
            <div className="col-11">
            <img className="width-100" src={process.env.PUBLIC_URL + `/images/over_decade.jpg`} />
            </div>

            <div className="col-1">
            
            </div>
        </div>

        <div className="container margin-top-and-botm">
          <div className="row">
                <p className="vision">
                Our goal to give<span className="font-bembo">Travellers</span>  far and wide seeking the 5 Ss of luxury travel to the <span className="font-bembo">Maldives:</span> Serenity, Sun, Sand, Sophistication and Serendipity.
                </p>
          </div>
      </div>

       
        <div className="bg-img-section" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + `/images/Our_Belief.jpg`})` }}>
          
        <div className="row ">
            <div className="col-12">
            <div className="we-got-you-2-mobile">
            <div>
            <h2 className="title-2 color-white">Our Belief  </h2>
              <p className="para color-white">We believe that relationships matter over a business and we make every effort in strengthening our relationships with you.
              </p>     

              <p className="para color-white">We are not conservative, we don’t always play by the rule, but integrity is a core component of our mission and vision.
              </p>  
            </div>
          </div>
            </div>
            
          </div>
          </div>



          <div className="row mt-15">
            <div className="col-12">
            <div className="we-got-you-2-mobile">
            <div>
              <h2 className="title-2">Meet The Faces Behind Your Next Holiday </h2>
              <p className="para">Our team of travel experts are located across the globe with unique experiences and are at the core of our operation as an industry leader. As a company that caters to the whole world, we understand the importance of good communication and having a diverse worldview.
               </p>

               <p className="para">This is why our team is multinational and well-exposed to the journeys we guide our customers through. It is the distinct charm of the Viluxur experience.
               </p>
                     
            </div>
          </div>
            </div>
          </div>




</div>


}





        

{isDesktopOrLaptop && 
      <div className="container-fluid mt-10">
        <div className="row">
              <div className="col-12 p-0">
              <motion.img className="width-100" src={process.env.PUBLIC_URL + `/images/Our_Belief.jpg`} />
              </div> 
        </div>     
     </div>

}


{isTabletOrMobile &&  


<div>



</div>


}


{isDesktopOrLaptop && 
     <div className="m-xy-s">
        <div className="row">
          <div className="col-5 m-m-t-15">
          <div className="text-section our-bilef">
            <div>
              <h2 className="title-2 color-white">Our Belief  </h2>
              <p className="para color-white">We believe that relationships matter over a business and we make every effort in strengthening our relationships with you.
              </p>     

              <p className="para color-white">We are not conservative, we don’t always play by the rule, but integrity is a core component of our mission and vision.
              </p>  
            </div>
          </div>
          </div>
          <div className="col-7">
        </div>
        </div>
    </div>
}


{isTabletOrMobile &&  


<div>



</div>


}

{isDesktopOrLaptop && 
    <div className="m-xy-s">
        <div className="row margin-top-and-botm">
          <div className="col-7 ">
          <div className=" ">
            <div>
              <h2 className="title-2 ">Meet The Faces Behind Your Next Holiday   </h2>
              <p className="para ">Our team of travel experts are located across the globe with unique experiences and are at the core of our operation as an industry leader. As a company that caters to the whole world, we understand the importance of good communication and having a diverse worldview. 
              </p>     

              <p className="para ">This is why our team is multinational and well-exposed to the journeys we guide our customers through. It is the distinct charm of the Viluxur experience. 
              </p>  
            </div>
          </div>
          </div>
          <div className="col-5">
        </div>
        </div>
        <div className="row">
            <div className="col-4 pdn-50-left-r">
            <img className="width-100" src={process.env.PUBLIC_URL + `/images/image3.jpg`} />
            <div className="content-inner padin-20">
                  <p className="para">Is a jack of all trades so to speak. The Managing Director of Viluxur Group, with experience in travel and tourism having worked in Sri Lanka, Singapore and the Maldives, has an eye for vision. He is a visionary, strategic marketer who looks a business venture from every angle before committing. This is the practice which has successfully expanded the Viluxur Group to what it is today. </p>
                  <h3 className="name"><span className="font-bembo">Shafraz ‘Shaffi’ Fazley</span></h3>
               
                </div>
            </div>
            <div className="col-4 pdn-50-left-r">
            <img className="width-100 " src={process.env.PUBLIC_URL + `/images/image.jpg`} />
            <div className="content-inner padin-20">
                  <p className="para">The Chief Operating Officer of Viluxur Group has over a decade of experience in the travel and tourism sectors of the Maldives. Ellice currently heads the sales divisions of Viluxur and OV. A go-getter with a positive attitude that does not believe in the word ‘No,’ Ellice’s sharp ideas and new age sales strategies are what keep OV and Viluxur in a constant loop of successful ROI.</p>
                  <h3 className="name"><span className="font-bembo">Ellic Bugayong</span></h3>
               
                </div>
            </div>
            <div className="col-4 pdn-50-left-r">
            <img className=" width-100" src={process.env.PUBLIC_URL + `/images/Nisha.jpg`} />
            <div className="content-inner padin-20">
                  <p className="para">With over 25 years of experience in the Tourism, Hospitality and MICE industry. Dr. Abu Bakar’s academic, research and consultancy expertise are in the areas of MICE, Destination marketing, Women in Tourism, Sustainable Tourism, Halal Tourism and Tourism education. </p>
                  <h3 className="name "><span className="font-bembo">Dr. Nisha Abu Bakar </span></h3>
               
                </div>
            </div>
        </div>
    </div>


}

{isTabletOrMobile &&  


<div>



</div>


}

{isDesktopOrLaptop && 

    <section className="container-fluid margin-top-and-botm mb-0-important">
        <div className="row">
        <div className="col-6">
        <motion.img className="width-100" src={process.env.PUBLIC_URL + `/images/Vi.jpg`} />
        </div>
        <div className="col-6 flex-colm-around">
        <div className="pdn-around-viluxur">
              <h2 className="title-2 ">Once a Viluxurian, Always a Viluxurian   </h2>
              <p className="para ">We believe in close connections and are always happy when our team members move to new aspirations.
              </p>     

              <p className="para ">Our former team memebers, too feel a ‘soul connection’ to their once place of employment and are always ready to share a good work on our behalf. 
              </p>  
            </div>

            <div className="always-viluxur-qut">
            <h3 className="name mt-01"><span className="font-bembo">"I learnt and had my biggest impactful career growth with Viluxur Holidays. I am always honored to have been part of that great adventure." </span></h3>

            <p className="para mt-5">LISWITH 
              </p>  
            </div>
        </div>
        </div>
        
    </section>

}

{isTabletOrMobile &&  


<div>



</div>


}


{isDesktopOrLaptop && 

    <div className="container-fluid ">
        <div className="row">
              <div className="col-12 p-0">
              <motion.img className="width-100" src={process.env.PUBLIC_URL + `/images/end_goal-new.jpg`} />
              </div> 
        </div>     
     </div>

}

{isDesktopOrLaptop && 
     <div className="m-xy-s">
        <div className="row">
          <div className="col-5 m-m-t-15">
          <div className="text-section our-bilef">
            <div>
              <h2 className="title-2 color-white">We Are Prepared  </h2>
              <p className="para color-white">At Viluxur Holidays, we believe in fully equipping our teams with the best tools they need to forge this trade. Upskilling our people is one of our priorities to ensure our teams have best equipped and ready to meet our travellers’ needs. 
              </p>     

              <p className="para color-white">One such instance was when Viluxur teamed up with Elevated Consultancy Singapore to learn how to network effectively and utilize our individual and team strengths to reach for the stars in the highly competitive global travel space.
              </p>
            </div>
          </div>
          </div>
          <div className="col-7">
        </div>
        </div>
    </div>
}
             */}


      <Footer />
    </div>
  );
}

const BannerRowTop = ({ title }) => {
  return (
    <div className={"banner-row"}>
      <div className="">
        <AnimatedLetters className="margin-left" title={"We're"} />
        <AnimatedLetters3 className="ml-4" title={'Viluxur'} />
      </div>


    </div>
  );
};

const BannerRowBottom = ({ title }) => {
  return (
    <div className={"banner-row center ml-5"}>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ ease: [0.6, 0.01, -0.05, 0.95], duration: 1, delay: 1 }} >
      </motion.div>
      <AnimatedLetters title={title} />
    </div>
  );
};

const BannerRowCenter = ({ title, playMarquee }) => {
  return (
    <div className={`banner-row marquee  ${playMarquee && "animate"}`}>
      <div className="marquee__inner">
        <AnimatedLetters title={'Serenity'} />
        <AnimatedLetters title={'Sun'} />
        <AnimatedLetters title={'Sand'} />
        <AnimatedLetters title={'Sophistication'} />
        <AnimatedLetters title={'Serendipity'} />
      </div>
    </div>
  );
};

const ImagesRow = ({ title, playMarquee }) => {
  return (
    <div className={`banner-row marquee  ${playMarquee && "animate"}`}>
      <div className="marquee__inner padding-redus logo-row-images">
        <Imagess1 />
        <Imagess2 />
        <Imagess1 />
        <Imagess3 />
        <Imagess1 />
        <Imagess4 />
        <Imagess1 />
        <Imagess5 />
        <Imagess1 />
        <Imagess6 />
        <Imagess1 />
        <Imagess7 />
        <Imagess1 />
        <Imagess8 />
        <Imagess1 />
        <Imagess9 />
        <Imagess1 />
        <Imagess2 />
        <Imagess1 />
        <Imagess3 />
        <Imagess1 />
        <Imagess4 />
      </div>
    </div>
  );
};


const Imagess1 = ({ title, disabled }) => (
  <motion.span
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
    <div>
      <img src={process.env.PUBLIC_URL + `/images/arrow.png`} />
    </div>
  </motion.span>
);


const Imagess2 = ({ title, disabled }) => (
  <motion.span
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
    <div>
      <img src={process.env.PUBLIC_URL + `/images/logos/logo_1.png`} />
    </div>
  </motion.span>
);


const Imagess3 = ({ title, disabled }) => (
  <motion.span
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
    <div>
      <img src={process.env.PUBLIC_URL + `/images/logos/logo_2.png`} />
    </div>
  </motion.span>
);

const Imagess4 = ({ title, disabled }) => (
  <motion.span
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
    <div>
      <img src={process.env.PUBLIC_URL + `/images/logos/logo_3.png`} />
    </div>
  </motion.span>
);


const Imagess5 = ({ title, disabled }) => (
  <motion.span
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
    <div>
      <img src={process.env.PUBLIC_URL + `/images/logos/logo_4.png`} />
    </div>
  </motion.span>
);

const Imagess6 = ({ title, disabled }) => (
  <motion.span
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
    <div>
      <img src={process.env.PUBLIC_URL + `/images/logos/logo_5.png`} />
    </div>
  </motion.span>
);

const Imagess7 = ({ title, disabled }) => (
  <motion.span
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
    <div>
      <img src={process.env.PUBLIC_URL + `/images/logos/logo_6.png`} />
    </div>
  </motion.span>
);

const Imagess8 = ({ title, disabled }) => (
  <motion.span
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
    <div>
      <img src={process.env.PUBLIC_URL + `/images/logos/logo_7.png`} />
    </div>
  </motion.span>
);

const Imagess9 = ({ title, disabled }) => (
  <motion.span
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
    <div>
      <img src={process.env.PUBLIC_URL + `/images/logos/logo_9.png`} />
    </div>
  </motion.span>
);


const AnimatedLetters = ({ title, disabled }) => (
  <motion.span
    className="row-title"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
    {[...title].map((letter) => (
      <motion.span variants={disabled ? null : letterAni} className="row-letter">{letter}</motion.span>
    ))}
  </motion.span>
);


const AnimatedLetters3 = ({ title, disabled }) => (
  <motion.span
    className="row-title-3"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
    {[...title].map((letter) => (
      <motion.span variants={disabled ? null : letterAni} className="row-letter">{letter}</motion.span>
    ))}
  </motion.span>
);

const AnimatedLetters2 = ({ title, disabled }) => (
  <motion.span
    className="row-title-gray"
    variants={disabled ? null : banner}
    initial="initial"
    animate="animate">
    {[...title].map((letter) => (
      <motion.span variants={disabled ? null : letterAni} className="row-letter">{letter}</motion.span>
    ))}
  </motion.span>
);

const BannerRowTopNew = ({ title }) => {
  return (
    <div className={"banner-row"}>
      <div className="">
        <AnimatedLetters title={title} />
      </div>

    </div>
  );
};

const BannerRowCenterNew = ({ title, playMarquee }) => {
  return (
    <div className={`banner-row marquee  ${playMarquee && "animate"}`}>
      <div className="marquee__inner luxury-m-titile">
        <AnimatedLetters title={title} />
      </div>
    </div>
  );
};

export default Vip;