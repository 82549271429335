import React, {useState} from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { NavLink, useLocation } from 'react-router-dom';
const Footer = () => {

// validation start
const [fname, setFName] = useState('');
const [lname, setLName] = useState('');
const [email, setEmail] = useState('');
const [phone, setPhone] = useState('');
const [massage, setMassage] = useState('');

const [FNameArray, setFNameArray] = useState([]);
const [LNameArray, setLNameArray] = useState([]);
const [emailArray, setEmailArray] = useState([]);
const [phoneArray, setPhoneArray] = useState([]);
const [massageArray, setMassageArray] = useState([]);


const FnameChange = (event) => {
  setFName(event.target.value);
}
const LnameChange = (event) => {
 setLName(event.target.value);
}

const emailChange = (event) => {
  setEmail(event.target.value);
}

const phoneChange = (event) => {
 setPhone(event.target.value);
}

const massageChange = (event) => {
 setMassage(event.target.value);
}




const [isActive, setActive] = useState(false);


const formSubmitHandler = (event) => {

 setActive(true)
 event.preventDefault();
 
 let return_status = true;
 let newList = [];
 let newListbudgect = [];
 let FNamevalue = [];
 let LNamevalue = [];
 let Emailvalue = [];
 let Phonevalue = [];
 let Massagevalue = [];

 if(fname == ""){
   FNamevalue.push(`Your First Name Required`);
   return_status = false;
   setActive(false)
 }

 if(lname == ""){
   LNamevalue.push(`Your Last Name Required`);
   return_status = false;
   setActive(false)
 }


 if(email == ""){
   Emailvalue.push(`Valid Email Address Required`);
   return_status = false;
   setActive(false)
 } else {
   var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

   if (!pattern.test(email)) {
       Emailvalue.push(`Please enter valid email Address`);
       return_status = false;
       setActive(false)
     }
 }

 if(phone == ""){
   Phonevalue.push(`Phone Number required`);
   return_status = false;
   setActive(false)
 }

 if(massage == ""){
   Massagevalue.push(`Massage required`);
   return_status = false;
   setActive(false)
 }


 setFNameArray(FNamevalue);
 setLNameArray(LNamevalue);
 setEmailArray(Emailvalue);
 setPhoneArray(Phonevalue);
 setMassageArray(Massagevalue);

 
 if (return_status) {
   fetch('https://www.viluxurholidays.com/thank-you-vh.php', {
     method: 'POST',
     headers: {
         "Content-Type": "application/json"
     },
     body: JSON.stringify({
     
       fldCustomerFormMessage1 : "Viluxur Form",
     
       fldCustomerFName : fname,
       fldCustomerLName : lname,
       fldCustomerEMail : email,
       fldCustomerPhone : phone,
       fldCustomerMassage : massage 
     

      
     })
 }).then((response) => response.json())
     .then( data =>{
         
         if (data.code == 200) {
           window.location.href = 'https://www.ovholidays.com/thankyou.php';
         } else {
           window.location.href = 'https://www.ovholidays.com/errorovx.php';
         }
       }).catch((error) => {
         console.log('not work');
         console.log(error);
     });
 }  
}
// validation end

const [state, setState] = useState({
  isPaneOpen: false,
  isPaneOpenLeft: false,
});


  //footer link 1
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };


  //footer link 2
  const [isHovering2, setIsHovering2] = useState(false);

  const handleMouseOver2 = () => {
    setIsHovering2(true);
  };

  const handleMouseOut2 = () => {
    setIsHovering2(false);
  };

   //footer link 3
   const [isHovering3, setIsHovering3] = useState(false);

   const handleMouseOver3 = () => {
     setIsHovering3(true);
   };
 
   const handleMouseOut3 = () => {
     setIsHovering3(false);
   };
 

     //footer link 4
     const [isHovering4, setIsHovering4] = useState(false);

     const handleMouseOver4 = () => {
       setIsHovering4(true);
     };
   
     const handleMouseOut4 = () => {
       setIsHovering4(false);
     };
   

        //footer link 5
        const [isHovering5, setIsHovering5] = useState(false);

        const handleMouseOver5 = () => {
          setIsHovering5(true);
        };
      
        const handleMouseOut5 = () => {
          setIsHovering5(false);
        };


  return (
    <div>
    <div className="footer">
        <div className="img ">
        {isHovering && (
          <div className="footer-img-container">
             <img className="img-item-1" src={process.env.PUBLIC_URL + `/images/footer/Inquire_banner.jpg`} />
          </div>
        )}

        {isHovering2 && (
          <div className="footer-img-container">
             <img className="img-item-1" src={process.env.PUBLIC_URL + `/images/footer/we_are_hiring.jpg`} />
          </div>
        )}
              {isHovering3 && (
          <div className="footer-img-container">
             <img className="img-item-1" src={process.env.PUBLIC_URL + `/images/Careers-new-img.jpg`} />
          </div>
        )}

{isHovering4 && (
          <div className="footer-img-container">
             <img className="img-item-1" src={process.env.PUBLIC_URL + `/images/footer/Instagram_Banner.jpg`} />
          </div>
        )}

{isHovering5 && (
          <div className="footer-img-container">
             <img className="img-item-1" src={process.env.PUBLIC_URL + `/images/Careers-new-img.jpg`} />
          </div>
        )}

        </div>
        <div className="f_title">
            <ul className="footer_link">
                <li className="hover-white gray-text-footer"  > <NavLink strict  to={`${process.env.PUBLIC_URL}/5s-of-luxury`} key={`${process.env.PUBLIC_URL}/5s-of-luxury`}  >
              <a >We Are <span className="footer-text-sec">Luxury<span className="footer-svg"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg></span></span> </a>
              </NavLink></li>
                <li className="hover-white gray-text-footer"><NavLink strict  to={`${process.env.PUBLIC_URL}/team`} key={`${process.env.PUBLIC_URL}/team`}  >
              <a >We Are <span className="footer-text-sec">Local Global<span className="footer-svg"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg></span></span></a>
              </NavLink></li>
                <li className="hover-white last-link-footer gray-text-footer" ><NavLink strict  to={`${process.env.PUBLIC_URL}/rebuild-now`} key={`${process.env.PUBLIC_URL}/rebuild-now`}  >
              <a >We Are <span className="footer-text-sec">The Future<span className="footer-svg"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg></span></span></a>
              </NavLink></li>
            
              <li className="hover-white last-link-footer gray-text-footer" ><NavLink strict  to={`${process.env.PUBLIC_URL}/vh-vip`} key={`${process.env.PUBLIC_URL}/vh-vip`}  >
              <a >Viluxur  <span className="footer-text-sec">Vip<span className="footer-svg"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg></span></span></a>
              </NavLink></li>
              <li className="hover-white last-link-footer gray-text-footer" ><NavLink strict  to={`${process.env.PUBLIC_URL}/vh-experience`} key={`${process.env.PUBLIC_URL}/vh-experience`}  >
              <a >Viluxur <span className="footer-text-sec">Experience<span className="footer-svg"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg></span></span></a>
              </NavLink></li>
          
            </ul> 
         </div>
     
    </div>

    <SlidingPane
              className="some-custom-class"
              overlayClassName="some-custom-overlay-class footer-form-mobile"
              isOpen={state.isPaneOpen}
              title=""
              width="57%"
              onRequestClose={() => {
                // triggered on "<" on left top click or on outside click
                setState({ isPaneOpen: false });
              }}
            >
              <div>
                  <div className="form-header content mb-5">
                    <h2 className="header-title">Let’s Get Planning </h2>
                    <p className="para-text mb-5">Fill in the form below and let’s begin planning your client’s next Maldivian getaway  </p>
                  </div>
              <form className="mt-5-form">
                <div className="row input-style">
                  <div className="col-12">

                    <div className="mb-5">
                      <p> First Name:</p>
                      <input type="text"  autoComplete="off" name="fldCustomerFName" onChange={FnameChange} />
                       <div className="error-waper">
                      <div className="erroe-msg">{FNameArray[0]}</div>
                       </div>
                    </div>
                    <div className="mb-5">
                      <p> Last Name:</p>
                      <input type="text"  autoComplete="off" name="fldCustomerLName" onChange={LnameChange} />
                      <div className="error-waper">
                      <div className="erroe-msg">{LNameArray[0]}</div>
                    </div>
                    </div>
                    <div className="mb-5">
                      <p> Email:</p>
                      <input type="text"  name="fldCustomerEmail" onChange={emailChange}/>
                      <div className="error-waper">
                      <div className="erroe-msg">{emailArray[0]}</div>
                      </div>
                    </div>
                    <div className="mb-5">
                      <p> Contact Number:</p>
                      <input type="text" name="fldCustomerPNumber" onChange={phoneChange}/>
                      <div className="error-waper">
                      <div className="erroe-msg">{phoneArray[0]}</div>
                    </div>
                    </div>
                    <div className="mb-5">
                    <p> Massage:</p>
                    <input className="height-aria" type="textarea"  name="fldCustomerMassage" onChange={massageChange}/>
                    <div className="error-waper">
                    <div className="erroe-msg">{massageArray[0]}</div>
                  </div>
                    </div>
                    <div >
                    <button className="submit-btn" type="submit" value="Submit" onClick={formSubmitHandler} >Submit</button>
                    </div>
                  </div>
                </div>
              </form>
              </div>
            
              
            </SlidingPane>



     <div className="copy_right">
      <span>
      © 2022 Viluxur Holidays 
      </span>
      <span>
        <a href="https://www.facebook.com/ViluxurHolidays/" target="_blank" className="footer-svg"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z"/></svg></a>
        <a href="https://www.instagram.com/viluxur.holidays/" target="_blank"  className="footer-svg footer-svg-mleft"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path d="M14.829 6.302c-.738-.034-.96-.04-2.829-.04s-2.09.007-2.828.04c-1.899.087-2.783.986-2.87 2.87-.033.738-.041.959-.041 2.828s.008 2.09.041 2.829c.087 1.879.967 2.783 2.87 2.87.737.033.959.041 2.828.041 1.87 0 2.091-.007 2.829-.041 1.899-.086 2.782-.988 2.87-2.87.033-.738.04-.96.04-2.829s-.007-2.09-.04-2.828c-.088-1.883-.973-2.783-2.87-2.87zm-2.829 9.293c-1.985 0-3.595-1.609-3.595-3.595 0-1.985 1.61-3.594 3.595-3.594s3.595 1.609 3.595 3.594c0 1.985-1.61 3.595-3.595 3.595zm3.737-6.491c-.464 0-.84-.376-.84-.84 0-.464.376-.84.84-.84.464 0 .84.376.84.84 0 .463-.376.84-.84.84zm-1.404 2.896c0 1.289-1.045 2.333-2.333 2.333s-2.333-1.044-2.333-2.333c0-1.289 1.045-2.333 2.333-2.333s2.333 1.044 2.333 2.333zm-2.333-12c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.958 14.886c-.115 2.545-1.532 3.955-4.071 4.072-.747.034-.986.042-2.887.042s-2.139-.008-2.886-.042c-2.544-.117-3.955-1.529-4.072-4.072-.034-.746-.042-.985-.042-2.886 0-1.901.008-2.139.042-2.886.117-2.544 1.529-3.955 4.072-4.071.747-.035.985-.043 2.886-.043s2.14.008 2.887.043c2.545.117 3.957 1.532 4.071 4.071.034.747.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886z"/></svg></a>
      </span>
    
     </div>
     </div>
  );
};

export default Footer;