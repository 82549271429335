import React, { useState, useEffect } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';
import { styled } from '@mui/material/styles';

const Label = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

const Images1 = ({ speed }) => {
  const { scrollYProgress } = useViewportScroll();
  const yValue = useTransform(scrollYProgress, [0, 1], [0, 200*speed]);

      return(
        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1}} style={{y:yValue}}>
           <img className="img-100" src={process.env.PUBLIC_URL + `/images/viluxur_home_page_we-are-viluxur.jpg`} />
        </motion.div>
      )
};

const Images2 = ({ speed }) => {
  const { scrollYProgress } = useViewportScroll();
  const yValue = useTransform(scrollYProgress, [0, 1], [0, 400*speed]);

      return(
        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1}} style={{y:yValue}}>
           <img className="img-100" src={process.env.PUBLIC_URL + `/images/we_are_viluxur_3.jpg`} />
        </motion.div>
      )
};

const Images3 = ({ speed }) => {
  const { scrollYProgress } = useViewportScroll();
  const yValue = useTransform(scrollYProgress, [0, 1], [0, 200*speed]);

      return(
        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1}} style={{y:yValue}}>
           <img className="img-100" src={process.env.PUBLIC_URL + `/images/viluxur_home_page.jpg`} />
        </motion.div>
      )
};

const Emma = ({ speed }) => {
  const { scrollYProgress } = useViewportScroll();
  const yValue = useTransform(scrollYProgress, [0, 1], [0, 200*speed]);

      return(
        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1}} style={{y:yValue}}>
           <img className="img-100" src={process.env.PUBLIC_URL + `/images/We_Are_Local_Global.jpg`} />
        </motion.div>
      )
};


const Ellice = ({ speed }) => {
  const { scrollYProgress } = useViewportScroll();
  const yValue = useTransform(scrollYProgress, [0, 1], [0, 200*speed]);

      return(
        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1}} style={{y:yValue}}>
           <img className="img-100" src={process.env.PUBLIC_URL + `/images/we_are_local_global_home_page.jpg`} />
        </motion.div>
      )
};


const Aniie = ({ speed }) => {
  const { scrollYProgress } = useViewportScroll();
  const yValue = useTransform(scrollYProgress, [0, 1], [0, 400*speed]);

      return(
        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1}} style={{y:yValue}}>
           <img className="img-100" src={process.env.PUBLIC_URL + `/images/viluxur_home_page_3-new.jpg`} />
        </motion.div>
      )
};


const Future1 = ({ speed }) => {
  const { scrollYProgress } = useViewportScroll();
  const yValue = useTransform(scrollYProgress, [0, 1], [0, 200*speed]);

      return(
        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1}} style={{y:yValue}}>
           <img className="img-100" src={process.env.PUBLIC_URL + `/images/we_are_the_future_3-new.jpg`} />
        </motion.div>
      )
};


const Future2 = ({ speed }) => {
  const { scrollYProgress } = useViewportScroll();
  const yValue = useTransform(scrollYProgress, [0, 1], [0, 400*speed]);

      return(
        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1}} style={{y:yValue}}>
           <img className="img-100" src={process.env.PUBLIC_URL + `/images/we_are_the_f.jpg`} />
        </motion.div>
      )
};


const Future3 = ({ speed }) => {
  const { scrollYProgress } = useViewportScroll();
  const yValue = useTransform(scrollYProgress, [0, 1], [0, 200*speed]);

      return(
        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1}} style={{y:yValue}}>
           <img className="img-100" src={process.env.PUBLIC_URL + `/images/we_are_the_future_2.jpg`} />
        </motion.div>
      )
};







const ImageSvg = ({ speed }) => {
  const { scrollYProgress } = useViewportScroll();
  const yValue = useTransform(scrollYProgress, [0, 1], [0, 200*speed]);

      return(
        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1}} style={{y:yValue}}>
           <img className="img-svg" src={process.env.PUBLIC_URL + `/images/we-are-viluxur.svg`} />
        </motion.div>
      )
};



const Neo = () => {

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  const [playMarquee, setPlayMarquee] = useState(false);
  useEffect(() => {
    setPlayMarquee(true);

  }, []);
  return (
<div className="neo">


{isDesktopOrLaptop && 
<div>

  <section className="container-fluid">
    <div className="row">
        <div className="col-12">

        <div className="we-got-you-2 ">
            <div>
              <span className="quote-icon-gray">
<svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="0" fill="none" width="24" height="24"/><g><path d="M11.192 15.757c0-.88-.23-1.618-.69-2.217-.326-.412-.768-.683-1.327-.812-.55-.128-1.07-.137-1.54-.028-.16-.95.1-1.956.76-3.022.66-1.065 1.515-1.867 2.558-2.403L9.373 5c-.8.396-1.56.898-2.26 1.505-.71.607-1.34 1.305-1.9 2.094s-.98 1.68-1.25 2.69-.346 2.04-.217 3.1c.168 1.4.62 2.52 1.356 3.35.735.84 1.652 1.26 2.748 1.26.965 0 1.766-.29 2.4-.878.628-.576.94-1.365.94-2.368l.002.003zm9.124 0c0-.88-.23-1.618-.69-2.217-.326-.42-.77-.692-1.327-.817-.56-.124-1.074-.13-1.54-.022-.16-.94.09-1.95.75-3.02.66-1.06 1.514-1.86 2.557-2.4L18.49 5c-.8.396-1.555.898-2.26 1.505-.708.607-1.34 1.305-1.894 2.094-.556.79-.97 1.68-1.24 2.69-.273 1-.345 2.04-.217 3.1.165 1.4.615 2.52 1.35 3.35.732.833 1.646 1.25 2.742 1.25.967 0 1.768-.29 2.402-.876.627-.576.942-1.365.942-2.368v.01z"/></g></svg></span>
              <h2 className="title-2 relatioive title-before sec-2">We Are Viluxur </h2>
              <p className="para">Viluxur Holidays is headquartered in Singapore, with a myriad of offices from Sri Lanka to Russia. 
              We have expanded with a global presence in being the most sought after travel club for bespoke and unique holidays for the discerning traveller. 
              </p>
              <p className="underline">
              <NavLink strict  to={`${process.env.PUBLIC_URL}/5s-0f-luxury`} key={`${process.env.PUBLIC_URL}/5s-0f-luxury`}  >
              <a className="flip-animate" ><span data-hover="Find out more">Find out more</span> </a>
              </NavLink>
             </p>         
            </div>
          </div>
        </div>
    </div>

  </section>
  
      <section className="container-fluid">

      <div className="row">
          <div className="col-7 mt-5-new">
          <Images1 speed={0}/>
          <div className="signature float-right">
          <p className="curve-font">
            Jessie jingwen
          </p>
          <p className="ds-post">General Manager - China </p>
          </div>
          </div>
          <div className="col-2">

          </div>
          <div className="col-3 we-are-viluxur-mt">
          <Images3  speed={0}/>
         
          </div>
          
        </div>
        
          <div className="row">
            <div className="col-12">
              <div className="image-container">
              <img className="img-svg" src={process.env.PUBLIC_URL + `/images/we-are-viluxur-2.svg`} />
              </div>
            
            </div>
          </div>
      </section>


      

      <section className="container-fluid mt-version-top">
        
          <div className="row">

          <div className="col-3 m-min-top-20">
         
           <Emma speed={0}/>
            </div>
            <div className="col-2"></div>
            <div className="col-5 min-top-we-are">
            <div className="we-got-you-3">
            <div>
            <span className="quote-icon-gray">
<svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="0" fill="none" width="24" height="24"/><g><path d="M11.192 15.757c0-.88-.23-1.618-.69-2.217-.326-.412-.768-.683-1.327-.812-.55-.128-1.07-.137-1.54-.028-.16-.95.1-1.956.76-3.022.66-1.065 1.515-1.867 2.558-2.403L9.373 5c-.8.396-1.56.898-2.26 1.505-.71.607-1.34 1.305-1.9 2.094s-.98 1.68-1.25 2.69-.346 2.04-.217 3.1c.168 1.4.62 2.52 1.356 3.35.735.84 1.652 1.26 2.748 1.26.965 0 1.766-.29 2.4-.878.628-.576.94-1.365.94-2.368l.002.003zm9.124 0c0-.88-.23-1.618-.69-2.217-.326-.42-.77-.692-1.327-.817-.56-.124-1.074-.13-1.54-.022-.16-.94.09-1.95.75-3.02.66-1.06 1.514-1.86 2.557-2.4L18.49 5c-.8.396-1.555.898-2.26 1.505-.708.607-1.34 1.305-1.894 2.094-.556.79-.97 1.68-1.24 2.69-.273 1-.345 2.04-.217 3.1.165 1.4.615 2.52 1.35 3.35.732.833 1.646 1.25 2.742 1.25.967 0 1.768-.29 2.402-.876.627-.576.942-1.365.942-2.368v.01z"/></g></svg></span>
              <h2 className="title-2 title-2 relatioive title-before sec-3">We Are Local Global  </h2>
              <p className="para">Our strongest aspect is our multicultural team. We are a part of a large global family. By global, we mean the individuals who are part of the Viluxur team.
              </p>
              <p className="underline">
              <NavLink strict  to={`${process.env.PUBLIC_URL}/team`} key={`${process.env.PUBLIC_URL}/team`}  >
              <a className="flip-animate" ><span data-hover="Find out more">Find out more</span> </a>
              </NavLink>
             </p>         
            </div>
          </div>
            </div>
            
            <div className="col-2 item-3">
           
         
            
            </div>

          </div>

          <div className="row">
            <div className="col-5">

            </div>
            <div className="col-7 we-are-local-mt">
            <Ellice className="" speed={0}/>
            </div>
        </div>

          <div className="row">
            <div className="col-12">
              <div className="emma-img">
              <img className="img-svg-2" src={process.env.PUBLIC_URL + `/images/we-are-global-2.svg`} />
              </div>
            
            </div>
          </div>
      </section>



      <section className="container-fluid the-future-section">
       
          <div className="row">

          <div className="col-2 item-1">
           

            </div>
            <div className="col-5">
           
           
            <div className="we-got-you-4">
            <div>
            <span className="quote-icon-gray">
<svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="0" fill="none" width="24" height="24"/><g><path d="M11.192 15.757c0-.88-.23-1.618-.69-2.217-.326-.412-.768-.683-1.327-.812-.55-.128-1.07-.137-1.54-.028-.16-.95.1-1.956.76-3.022.66-1.065 1.515-1.867 2.558-2.403L9.373 5c-.8.396-1.56.898-2.26 1.505-.71.607-1.34 1.305-1.9 2.094s-.98 1.68-1.25 2.69-.346 2.04-.217 3.1c.168 1.4.62 2.52 1.356 3.35.735.84 1.652 1.26 2.748 1.26.965 0 1.766-.29 2.4-.878.628-.576.94-1.365.94-2.368l.002.003zm9.124 0c0-.88-.23-1.618-.69-2.217-.326-.42-.77-.692-1.327-.817-.56-.124-1.074-.13-1.54-.022-.16-.94.09-1.95.75-3.02.66-1.06 1.514-1.86 2.557-2.4L18.49 5c-.8.396-1.555.898-2.26 1.505-.708.607-1.34 1.305-1.894 2.094-.556.79-.97 1.68-1.24 2.69-.273 1-.345 2.04-.217 3.1.165 1.4.615 2.52 1.35 3.35.732.833 1.646 1.25 2.742 1.25.967 0 1.768-.29 2.402-.876.627-.576.942-1.365.942-2.368v.01z"/></g></svg></span>
              <h2 className="title-2 title-2 relatioive title-before sec-4">We Are The Future  </h2>
              <p className="para">We at Viluxur Holidays strongly believe that we are the future of the travel and tourism industry. Having a proverbial footprint around the globe, we hope to support economic development and human well-being, with a focus on affordable and equitable access for all. 
              </p>
              <p className="underline">
              <NavLink strict  to={`${process.env.PUBLIC_URL}/rebuild-now`} key={`${process.env.PUBLIC_URL}/rebuild-now`}  >
              <a className="flip-animate" ><span data-hover="Find out more">Find out more</span> </a>
              </NavLink>
             </p>         
            </div>
          </div>
            </div>
            <div className="col-2"></div>
            <div className="col-3 item-3-new ">
            <Future3 speed={0}/>
        
            
            </div>

          </div>

          <div className="row">
            <div className="col-7 we-are-future-mt">
            <Future2 className="ml-2" speed={0}/>
            </div>
            <div className="col-5">

            </div>
        </div>
          <div className="row">
            <div className="col-12">
              <div className="last-round">
              <img className="img-svg" src={process.env.PUBLIC_URL + `/images/We-Are-The-Future-2.svg`} />
              </div>
            
            </div>
          </div>
      </section>

      <section className="container mt-a5 mb-5">
          <div className="row mb-5">
          <span className="quote-icon-gray-2">
<svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="0" fill="none" width="24" height="24"/><g><path d="M11.192 15.757c0-.88-.23-1.618-.69-2.217-.326-.412-.768-.683-1.327-.812-.55-.128-1.07-.137-1.54-.028-.16-.95.1-1.956.76-3.022.66-1.065 1.515-1.867 2.558-2.403L9.373 5c-.8.396-1.56.898-2.26 1.505-.71.607-1.34 1.305-1.9 2.094s-.98 1.68-1.25 2.69-.346 2.04-.217 3.1c.168 1.4.62 2.52 1.356 3.35.735.84 1.652 1.26 2.748 1.26.965 0 1.766-.29 2.4-.878.628-.576.94-1.365.94-2.368l.002.003zm9.124 0c0-.88-.23-1.618-.69-2.217-.326-.42-.77-.692-1.327-.817-.56-.124-1.074-.13-1.54-.022-.16-.94.09-1.95.75-3.02.66-1.06 1.514-1.86 2.557-2.4L18.49 5c-.8.396-1.555.898-2.26 1.505-.708.607-1.34 1.305-1.894 2.094-.556.79-.97 1.68-1.24 2.69-.273 1-.345 2.04-.217 3.1.165 1.4.615 2.52 1.35 3.35.732.833 1.646 1.25 2.742 1.25.967 0 1.768-.29 2.402-.876.627-.576.942-1.365.942-2.368v.01z"/></g></svg></span>
                <p className="vision">
                We <span className="font-bembo">Believe</span> that relationships matter over a business and we make <span className="font-bembo">Every</span> effort in strengthening our <span className="font-bembo">Relationships</span> with you.
                </p>
          </div>
      </section>
{/* 
    <section className="mt-5 align-center-flex ">
            <motion.div className="banner mt-top-5" >
          
          <BannerRowCenter2 className="middle-title"  playMarquee={playMarquee} />
          
            </motion.div>
    </section> */}

<section className="container gallery mt-n-5">
          <div className="row">
              <div className="col-12">
              <Box >
      <Masonry columns={4} spacing={6}>
        {itemData.map((item, index) => (
          <div key={index}>

            <img
              src={`${item.img}?w=165&auto=format`}
              srcSet={`${item.img}?w=165&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                display: 'block',
                width: '100%',
              }}
            />
          </div>
        ))}
      </Masonry>
    </Box>
              </div>
          </div>
      </section>


    </div>

}


{isTabletOrMobile && 



    <div>

    <section className="container-fluid">

        <div className="row">
            <div className="col-11">
            <img className="width-100" src={process.env.PUBLIC_URL + `/images/image-1.jpg`} />
            </div>

            <div className="col-1">
            
            </div>
        </div>
          
          <div className="row row-top-min mobile-row-top">
            <div className="col-12">
            <div className="we-got-you-2">
            <div>
            <span className="quote-icon-gray">
              <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="0" fill="none" width="24" height="24"/><g><path d="M11.192 15.757c0-.88-.23-1.618-.69-2.217-.326-.412-.768-.683-1.327-.812-.55-.128-1.07-.137-1.54-.028-.16-.95.1-1.956.76-3.022.66-1.065 1.515-1.867 2.558-2.403L9.373 5c-.8.396-1.56.898-2.26 1.505-.71.607-1.34 1.305-1.9 2.094s-.98 1.68-1.25 2.69-.346 2.04-.217 3.1c.168 1.4.62 2.52 1.356 3.35.735.84 1.652 1.26 2.748 1.26.965 0 1.766-.29 2.4-.878.628-.576.94-1.365.94-2.368l.002.003zm9.124 0c0-.88-.23-1.618-.69-2.217-.326-.42-.77-.692-1.327-.817-.56-.124-1.074-.13-1.54-.022-.16-.94.09-1.95.75-3.02.66-1.06 1.514-1.86 2.557-2.4L18.49 5c-.8.396-1.555.898-2.26 1.505-.708.607-1.34 1.305-1.894 2.094-.556.79-.97 1.68-1.24 2.69-.273 1-.345 2.04-.217 3.1.165 1.4.615 2.52 1.35 3.35.732.833 1.646 1.25 2.742 1.25.967 0 1.768-.29 2.402-.876.627-.576.942-1.365.942-2.368v.01z"/></g></svg></span>
              <h2 className="title-2">We Are Viluxur </h2>
              <p className="para">Viluxur Holidays is headquartered in Singapore, with a myriad of offices from Sri Lanka to Russia. 
              We have expanded with a global presence in being the most sought after travel club for bespoke and unique holidays for the discerning traveller. 
              </p>
              <p className="underline">
              <NavLink strict  to={`${process.env.PUBLIC_URL}/5s-0f-luxury`} key={`${process.env.PUBLIC_URL}/5s-0f-luxury`}  >
              <a className="flip-animate" ><span data-hover="Find out more">Find out more</span> </a>
              </NavLink>
             </p>         
            </div>
          </div>
            </div>
            
          </div>
         
      </section>


<section className="container-fluid mt-flied-sec">

  

<div className="row">
    <div className="col-1">
    </div>

    <div className="col-11">
    <img className="width-100" src={process.env.PUBLIC_URL + `/images/image-4.jpg`} />
    </div>
</div>
    <div className="row row-top-min mobile-row-top">
            <div className="col-12">
            <div className="we-got-you-2">
            <div>
            <span className="quote-icon-gray">
              <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="0" fill="none" width="24" height="24"/><g><path d="M11.192 15.757c0-.88-.23-1.618-.69-2.217-.326-.412-.768-.683-1.327-.812-.55-.128-1.07-.137-1.54-.028-.16-.95.1-1.956.76-3.022.66-1.065 1.515-1.867 2.558-2.403L9.373 5c-.8.396-1.56.898-2.26 1.505-.71.607-1.34 1.305-1.9 2.094s-.98 1.68-1.25 2.69-.346 2.04-.217 3.1c.168 1.4.62 2.52 1.356 3.35.735.84 1.652 1.26 2.748 1.26.965 0 1.766-.29 2.4-.878.628-.576.94-1.365.94-2.368l.002.003zm9.124 0c0-.88-.23-1.618-.69-2.217-.326-.42-.77-.692-1.327-.817-.56-.124-1.074-.13-1.54-.022-.16-.94.09-1.95.75-3.02.66-1.06 1.514-1.86 2.557-2.4L18.49 5c-.8.396-1.555.898-2.26 1.505-.708.607-1.34 1.305-1.894 2.094-.556.79-.97 1.68-1.24 2.69-.273 1-.345 2.04-.217 3.1.165 1.4.615 2.52 1.35 3.35.732.833 1.646 1.25 2.742 1.25.967 0 1.768-.29 2.402-.876.627-.576.942-1.365.942-2.368v.01z"/></g></svg></span>
              <h2 className="title-2 title-2 relatioive title-before sec-3">We Are Local Global  </h2>
              <p className="para">Our strongest aspect is our multicultural team. We are a part of a large global family. By global, we mean the individuals who are part of the Viluxur team.
              </p>
              <p className="underline">
              <NavLink strict  to={`${process.env.PUBLIC_URL}/team`} key={`${process.env.PUBLIC_URL}/team`}  >
              <a className="flip-animate" ><span data-hover="Find out more">Find out more</span> </a>
              </NavLink>
             </p>         
            </div>
          </div>
            </div>
            
          </div>

  


 
</section>


<section className="container-fluid ">

    <div className="row">
        <div className="col-11">
        <img className="width-100" src={process.env.PUBLIC_URL + `/images/we_are_the_f.jpg`} />
        </div>
        <div className="col-1">
        </div>
    </div>
  

  <div className="row row-top-min-3">

    <div className="col-12">
    <div className="we-got-you-4">
            <div>
            <span className="quote-icon-gray">
              <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="0" fill="none" width="24" height="24"/><g><path d="M11.192 15.757c0-.88-.23-1.618-.69-2.217-.326-.412-.768-.683-1.327-.812-.55-.128-1.07-.137-1.54-.028-.16-.95.1-1.956.76-3.022.66-1.065 1.515-1.867 2.558-2.403L9.373 5c-.8.396-1.56.898-2.26 1.505-.71.607-1.34 1.305-1.9 2.094s-.98 1.68-1.25 2.69-.346 2.04-.217 3.1c.168 1.4.62 2.52 1.356 3.35.735.84 1.652 1.26 2.748 1.26.965 0 1.766-.29 2.4-.878.628-.576.94-1.365.94-2.368l.002.003zm9.124 0c0-.88-.23-1.618-.69-2.217-.326-.42-.77-.692-1.327-.817-.56-.124-1.074-.13-1.54-.022-.16-.94.09-1.95.75-3.02.66-1.06 1.514-1.86 2.557-2.4L18.49 5c-.8.396-1.555.898-2.26 1.505-.708.607-1.34 1.305-1.894 2.094-.556.79-.97 1.68-1.24 2.69-.273 1-.345 2.04-.217 3.1.165 1.4.615 2.52 1.35 3.35.732.833 1.646 1.25 2.742 1.25.967 0 1.768-.29 2.402-.876.627-.576.942-1.365.942-2.368v.01z"/></g></svg></span>
              <h2 className="title-2">We Are The Future  </h2>
              <p className="para">We at Viluxur Holidays strongly believe that we are the future of the travel and tourism industry. Having a proverbial footprint around the globe, we hope to support economic development and human well-being, with a focus on affordable and equitable access for all. 
              </p>
              <p className="underline">
              <NavLink strict  to={`${process.env.PUBLIC_URL}/rebuild-now`} key={`${process.env.PUBLIC_URL}/rebuild-now`}  >
              <a className="flip-animate" ><span data-hover="Find out more">Find out more</span> </a>
              </NavLink>
             </p>         
            </div>
          </div>
    </div>

  </div>
 
</section>


<section className="container mt-a5 mb-5 mobile-para">
          <div className="row mb-5">
             <span className="quote-icon-gray-2">
              <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="0" fill="none" width="24" height="24"/><g><path d="M11.192 15.757c0-.88-.23-1.618-.69-2.217-.326-.412-.768-.683-1.327-.812-.55-.128-1.07-.137-1.54-.028-.16-.95.1-1.956.76-3.022.66-1.065 1.515-1.867 2.558-2.403L9.373 5c-.8.396-1.56.898-2.26 1.505-.71.607-1.34 1.305-1.9 2.094s-.98 1.68-1.25 2.69-.346 2.04-.217 3.1c.168 1.4.62 2.52 1.356 3.35.735.84 1.652 1.26 2.748 1.26.965 0 1.766-.29 2.4-.878.628-.576.94-1.365.94-2.368l.002.003zm9.124 0c0-.88-.23-1.618-.69-2.217-.326-.42-.77-.692-1.327-.817-.56-.124-1.074-.13-1.54-.022-.16-.94.09-1.95.75-3.02.66-1.06 1.514-1.86 2.557-2.4L18.49 5c-.8.396-1.555.898-2.26 1.505-.708.607-1.34 1.305-1.894 2.094-.556.79-.97 1.68-1.24 2.69-.273 1-.345 2.04-.217 3.1.165 1.4.615 2.52 1.35 3.35.732.833 1.646 1.25 2.742 1.25.967 0 1.768-.29 2.402-.876.627-.576.942-1.365.942-2.368v.01z"/></g></svg></span>
                <p className="vision">
                We <span className="font-bembo">Believe</span> that relationships matter over a business and we make <span className="font-bembo">Every</span> effort in strengthening our <span className="font-bembo">Relationships</span> with you.
                </p>
          </div>
      </section>


      <section className="container">
          <div className="row">
              <div className="col-12 p-lr-30">
              <Box >
              <Masonry columns={2} spacing={4}>
                {itemData.map((item, index) => (
                  <div key={index}>
                    <img
                      src={`${item.img}?w=165&auto=format`}
                      srcSet={`${item.img}?w=165&auto=format&dpr=2 2x`}
                      alt={item.title}
                      loading="lazy"
                      style={{
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        display: 'block',
                        width: '100%',
                      }}
                    />
                  </div>
                ))}
              </Masonry>
              </Box>
              </div>
          </div>
      </section>

    {/* <section className="mt-5 align-center-flex">
            <motion.div className="banner mt-10" >
          
          <BannerRowCenter2 className="middle-title"  playMarquee={playMarquee} />
          
            </motion.div>
    </section> */}



    </div>
 

}
    </div>
  );
};


const itemData = [
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/PG_1.jpg',
  
  },
  
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/PG_1T.jpg',
   
  },
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/PG_2.jpg',
   
  },
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/PG_2T.jpg',
   
  },
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/PG_4T.jpg',
    
  },
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/PG_3.jpg',
    
  },
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/PG_3T.jpg',
    
  },
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/PG_6.jpg',
    
  },
  
  
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/PG_5T.jpg',
    
  },
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/PG_5.jpg',
    
  },
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/PG_4.jpg',
   
  },
  {
    img: 'https://www.viluxurholidays.com/images/new-viluxur/PG_7.jpg',
    
  },
 


];

const Animatedimag = ({ title, disabled }) => (
  <motion.span>
  <img className="width-200 " src={process.env.PUBLIC_URL + `/images/Slide/1.jpg`} />
  </motion.span>
);

const Animatedimag2 = ({ title, disabled }) => (
  <motion.span>
  <img className="width-200 " src={process.env.PUBLIC_URL + `/images/Slide/2.jpg`} />
  </motion.span>
);

const Animatedimag3 = ({ title, disabled }) => (
  <motion.span>
  <img className="width-200 " src={process.env.PUBLIC_URL + `/images/Slide/3.jpg`} />
  </motion.span>
);

const Animatedimag4 = ({ title, disabled }) => (
  <motion.span>
  <img className="width-200 " src={process.env.PUBLIC_URL + `/images/Slide/4.jpg`} />
  </motion.span>
);

const Animatedimag5 = ({ title, disabled }) => (
  <motion.span>
  <img className="width-200 " src={process.env.PUBLIC_URL + `/images/Slide/5.jpg`} />
  </motion.span>
);





const BannerRowCenter2 = ({ title, playMarquee }) => {
  return (
    <div className={`banner-row marquee  ${playMarquee && "animate"}`}>
      <div className="marquee__inner">
      <Animatedimag  />
      <Animatedimag2  />
      <Animatedimag3  />
      <Animatedimag4  />
      <Animatedimag5  />
      <Animatedimag  />
      <Animatedimag2  />
     
      </div>
    </div>
  );
};


export default Neo;
