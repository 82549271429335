import React, { Component, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { render } from "react-dom";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {useNavigate} from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

 
const Header = () => {



  const [countryNameMap, setCountryNameMap] = useState([]);

  const [BoxTypeOfHolidayValue, setBoxTypeOfHolidayValue] = useState([]);
  const [typeOfHoliday, setTypeOfHoliday] = useState(true);

  const [CountryPname, sethandleCountryPname] = useState(0);
  const [adults, setadults] = useState(0);
  const [children,setchildren] = React.useState('');  

  const handleCountryPname = (event) => {
    sethandleCountryPname(event.target.value);
  }



  const handleChangeadults = (event) => {

    console.log(event);
    setadults(event.target.value);
  };
  

  const handleChangechildren = (event) => {
    setchildren(event.target.value);
  };



  const checkBoxTypeOfHoliday = (event) => {
    let newArray3 = [...BoxTypeOfHolidayValue, event.target.value, ];
    if (BoxTypeOfHolidayValue.includes(event.target.value)) {
    newArray3 = newArray3.filter(day2 => day2 !== event.target.value);
  }

  setBoxTypeOfHolidayValue(newArray3)

  if (newArray3.length > 0) {
    setTypeOfHoliday(false);
  } else {
    setTypeOfHoliday(true);
  }

  }


  useEffect(()=>{

    fetch('https://www.ovholidays.com/experience/get-country-all.php', {
      method: 'GET',
      headers: {
          "Content-Type": "application/json"
      }
       }).then((response) => response.json())
      .then(data => 
        
        setCountryNameMap(data)
        // console.log(data)
        )
      .catch((error) => {
          console.log('not work');
          console.log(error);
      });



  },[])



  // validation start
 const [fname, setFName] = useState('');
 const [lname, setLName] = useState('');
 const [email, setEmail] = useState('');
 const [phone, setPhone] = useState('');
 const [whatsapp, setWhatsapp] = useState('');

 const [FNameArray, setFNameArray] = useState([]);
 const [LNameArray, setLNameArray] = useState([]);
 const [emailArray, setEmailArray] = useState([]);
 const [phoneArray, setPhoneArray] = useState([]);
 const [whatsappArray, setWhatsappArray] = useState([]);



 const FnameChange = (event) => {
   setFName(event.target.value);
 }
 const LnameChange = (event) => {
  setLName(event.target.value);
}

 const emailChange = (event) => {
   setEmail(event.target.value);
 }

 const phoneChange = (event) => {
  setPhone(event.target.value);
}

const whatsappChange = (event) => {
  setWhatsapp(event.target.value);
}

const [isActive, setActive] = useState(false);

const formSubmitHandler = (event) => {

  setActive(true)
  event.preventDefault();
  
  let return_status = true;
  let newList = [];
  let newListbudgect = [];
  let FNamevalue = [];
  let LNamevalue = [];
  let Emailvalue = [];
  let Phonevalue = [];
  let Whatsappvalue = [];
  let Massagevalue = [];

  if(fname == ""){
    FNamevalue.push(`Full Name Required`);
    return_status = false;
    setActive(false)
  }

  if(lname == ""){
    LNamevalue.push(`Travel Agency / Tour Operator Name Name Required`);
    return_status = false;
    setActive(false)
  }


  if(email == ""){
    Emailvalue.push(`Valid Email Address Required`);
    return_status = false;
    setActive(false)
  } else {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (!pattern.test(email)) {
        Emailvalue.push(`Please enter valid email Address`);
        return_status = false;
        setActive(false)
      }
  }
 
  if(phone == ""){
    Phonevalue.push(`Company Number required`);
    return_status = false;
    setActive(false)
  }

  if(whatsapp  == ""){
    Whatsappvalue.push(`Whatsapp Number required`);
    return_status = false;
    setActive(false)
  }
 
 

  setFNameArray(FNamevalue);
  setLNameArray(LNamevalue);
  setEmailArray(Emailvalue);
  setPhoneArray(Phonevalue);
  setWhatsappArray(Whatsappvalue);
 

  
  if (return_status) {
    fetch('https://www.viluxurholidays.com/thank-you-vh.php', {
      method: 'POST',
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify({
      
        fldCustomerFormMessage1 : "Viluxur Form",
      
        fldCustomerFName : fname,
        fldTravelAgancyName : lname,
        fldCustomerEMail : email,
        fldCustomerPhone : phone,
        fldCustomerWhatsapp : whatsapp,
        fldCountryName:CountryPname,
        fldAdults:adults,
        fldChildren:children,
        fldtypeOfHoliday:typeOfHoliday


      

       
      })
  }).then((response) => response.json())
      .then( data =>{
          
          if (data.code == 200) {
            window.location.href = 'https://www.ovholidays.com/thankyou.php';
          } else {
            window.location.href = 'https://www.ovholidays.com/errorovx.php';
          }
        }).catch((error) => {
          console.log('not work');
          console.log(error);
      });
  }  
}
// validation end




const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

let navigate = useNavigate();

  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

 
  return (
    <div>

{isDesktopOrLaptop && 

<motion.div
initial={{ opacity: 0, y: -180 }}
animate={{ opacity: 1, y: 0 }}
transition={{
  ease: "easeInOut",
  duration: 1,
  delay: 0.6,}}
  className="header"
>
<motion.div className="header-inner">
   
    <div className="logo">
    <NavLink strict exact to={`${process.env.PUBLIC_URL}/`} key={`${process.env.PUBLIC_URL}/`}  >
        <span className="logo"> Viluxur Holidays </span>
    </NavLink> </div>
    <nav className="nav">
      <li>
        <NavLink strict exact to={`${process.env.PUBLIC_URL}/5s-of-luxury`} key={`${process.env.PUBLIC_URL}/5s-of-luxury`}  >
        <a className="flip-animate" ><span data-hover="5 Ss of Luxury">5 Ss of Luxury</span> </a>
        </NavLink>
      </li>

      <li>
        <NavLink strict exact to={`${process.env.PUBLIC_URL}/team`} key={`${process.env.PUBLIC_URL}/team`}  >
        <a className="flip-animate" ><span data-hover="Team">Team</span> </a>
        </NavLink>
      </li>

      <li>
        <NavLink strict exact to={`${process.env.PUBLIC_URL}/rebuild-now`} key={`${process.env.PUBLIC_URL}/rebuild-now`}  >
        <a className="flip-animate" ><span data-hover="Rebuild NOW">Rebuild NOW</span> </a>
        </NavLink>
      </li>
      
    </nav>
    <div className="contact">
      <a  onClick={() => setState({ isPaneOpen: true })} className="flip-animate hover-event" ><span data-hover="Let's work together">Let's work together</span></a>

      <SlidingPane
        className="some-custom-class"
        overlayClassName="some-custom-overlay-class"
        isOpen={state.isPaneOpen}
        title=""
        width="60%"
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setState({ isPaneOpen: false });
    }}
  >
            <div>
              <div className="form-header content mb-5">
              <h2 className="header-title">Let’s Get Planning </h2>
              <p className="para-text mb-5">Fill in the form below and let’s begin planning your client’s next Maldivian getaway  </p>
            </div>

          <form className="mt-5-form">
          <div className="row input-style">
            <div className="col-6">
            <p className="para-form"> Full Name:</p>
            <input type="text"  autoComplete="off" name="fldCustomerFName" onChange={FnameChange} />
            <div className="error-waper">
            <div className="erroe-msg">{FNameArray[0]}</div>
          </div>
          </div>
            <div className="col-6">
            <p className="para-form"> Travel Agency / Tour Operator Name</p>
            <input type="text"  autoComplete="off" name="fldCustomerLName" onChange={LnameChange} />
            <div className="error-waper">
            <div className="erroe-msg">{LNameArray[0]}</div>
          </div>
          </div>
          </div>

            
          <div className="row input-style">
            <div className="col-6">
            <p className="para-form"> Country Of Origin </p>
            <select className="width-100 outline-none"
              labelId="country_id"
              id="country_id"
              defaultValue={""}
              onChange={handleCountryPname}
              name="fldCustomerFormResort1ID"
            >

              {countryNameMap.map(item=> (
                <option value={item.fldCountryName}>{item.fldCountryName}</option>
              ))}
            </select>
            </div>
            <div className="col-6">
            <p className="para-form"> Email:</p>
            <input type="text"  name="fldCustomerEmail" onChange={emailChange}/>
            <div className="error-waper">
            <div className="erroe-msg">{emailArray[0]}</div>
            </div>
            </div>
          </div>

            <div className="row input-style">
            <div className="col-6">
            <p className="para-form"> Company Number </p>
            <input type="text" name="fldCustomerPNumber" onChange={phoneChange}/>
            <div className="error-waper">
            <div className="erroe-msg">{phoneArray[0]}</div>
            </div>
            </div>
            <div className="col-6">
            <p className="para-form"> Whatsapp Number </p>
            <input type="text" name="fldCustomerPNumber" onChange={whatsappChange}/>
            <div className="error-waper">
            <div className="erroe-msg">{whatsappArray[0]}</div>
          </div>
            </div>
            
          </div>

          <div className="row input-style">
            <div className="row">
                  <div className="col-6">
                  <p className="para-form mb-20">Number Of Pax</p>
                  </div>
                  <div className="col-6">
                  <p className="para-form mb-20">Mice Or Leisure Travel?</p>
                  </div>
            </div>
            <div className="col-6 plr-3 flex-align-center">
                           <select 
                            className="nightSelect mr-right-form outline-none "
                              labelId="adults"
                              id="adults"
                              value={adults}
                              name="fldCustomerFormadults"
                              onChange={handleChangeadults}
                            >
                                <option  value={"2 Adults"}>1 Adults</option>
                                <option  value={"2 Adults"}>2 Adults</option>
                                <option value={"3 Adults"}>3 Adults</option> 
                                <option value={"4 Adults"}>4 Adults</option>
                                <option value={"5 Adults"}>5 Adults</option>
                                <option value={"6 Adults"}>6 Adults</option>
                                <option value={"7 Adults"}>7 Adults</option>
                                <option value={"8 Adults"}>8 Adults</option> 
                                <option value={"9 Adults"}>9 Adults</option>
                                <option value={"10 Adults"}>10 Adults</option>
                            </select>

                           <select 
                            className="nightSelect outline-none"
                              labelId="adults"
                              id="adults"
                              value={children}
                              name="fldCustomerFormadults"
                              onChange={handleChangechildren}
                            >
                                <option value={"0 children"}>0 Children</option>
                                <option value={"1 children"}>1 Child</option>
                                <option value={"2 children"}>2 Children</option> 
                                <option value={"3 children"}>3 Children</option>
                                <option value={"4 children"}>4 Children</option>
                            </select>
            </div>
            <div className="col-6">
            
            <FormGroup className="display-inline-row">
            <FormControlLabel className="form-checkpoint" onChange={checkBoxTypeOfHoliday} control={<Checkbox  name="fldCustomerFormTypeOFMice" value="Mice" />} label="Mice"/>
            <FormControlLabel className="form-checkpoint" onChange={checkBoxTypeOfHoliday} control={<Checkbox name="fldCustomerFormTypeOFLeisureTravel" value="Leisure Travel"  /> } label="Leisure Travel"/>
            </FormGroup>
            </div>
          
          </div>
          <div className="row input-style mt-50">
          <button className="submit-btn" type="submit" value="Submit" onClick={formSubmitHandler} >Submit</button>
          </div>
          
        </form>
       
        </div>
   
    
  </SlidingPane>

    </div>
    
  </motion.div>
</motion.div>

}


{isTabletOrMobile && 

<motion.div
initial={{ opacity: 0, y: -180 }}
animate={{ opacity: 1, y: 0 }}
transition={{
  ease: "easeInOut",
  duration: 1,
  delay: 0.6,}}
  className=""
>

<motion.div>
  <div className="container">
      <div className="row">
        <div className="col-6 header-logo">
        <NavLink strict exact to={`${process.env.PUBLIC_URL}/`} key={`${process.env.PUBLIC_URL}/`}  >
        <img src={process.env.PUBLIC_URL + `/images/Viluxur_Holidays_logo_new_gold.svg`} />
        </NavLink>
        </div>
        <div className="col-6 flex">
          <div className="header-call-to-action">
         <a  href="tel:0112372888" ><img src={process.env.PUBLIC_URL + `/images/call-svgrepo-com-01.svg`} /></a> 
          </div>
        <nav role="navigation">
        <div id="menuToggle">
   
        <input type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
            <ul id="menu">
              <li className="header-logo mb-5">
              <NavLink strict exact to={`${process.env.PUBLIC_URL}/`} key={`${process.env.PUBLIC_URL}/`}  >
              <img src={process.env.PUBLIC_URL + `/images/Viluxur_Holidays_logo_new_gold.svg`} />
              </NavLink>
              </li>
              <li>
                <NavLink strict exact to={`${process.env.PUBLIC_URL}/5s-of-luxury`} key={`${process.env.PUBLIC_URL}/5s-of-luxury`}  >
                <a className="arrow-righ">We are viluxur </a>
                </NavLink>
              </li>

              <li>
                <NavLink strict exact to={`${process.env.PUBLIC_URL}/team`} key={`${process.env.PUBLIC_URL}/team`}  >
                <a className="arrow-righ">We are local global</a>
                </NavLink>
              </li>

              <li>
                <NavLink strict exact to={`${process.env.PUBLIC_URL}/rebuild-now`} key={`${process.env.PUBLIC_URL}/rebuild-now`}  >
                <a className="arrow-righ">We are the future</a>
                </NavLink>
              </li>

              
              <li>
                <NavLink strict exact to={`${process.env.PUBLIC_URL}/vh-vip`} key={`${process.env.PUBLIC_URL}/vh-vip`}  >
                <a className="arrow-righ">Viluxur Vip
</a>
                </NavLink>
              </li>

              
              <li>
                <NavLink strict exact to={`${process.env.PUBLIC_URL}/vh-experience`} key={`${process.env.PUBLIC_URL}/vh-experience`}  >
                <a className="arrow-righ">Viluxur Experience</a>
                </NavLink>
              </li>

              <li>
              <div className="contact">
              <a className="arrow-righ" onClick={() => setState({ isPaneOpen: true })} >Let's work together</a>

              <SlidingPane
              className="some-custom-class"
              overlayClassName="some-custom-overlay-class"
              isOpen={state.isPaneOpen}
              title=""
              width="97%"
              onRequestClose={() => {
                // triggered on "<" on left top click or on outside click
                setState({ isPaneOpen: false });
              }}
            >
              <div>
                  <div className="form-header content mb-5">
                    <h2 className="header-title">Let’s Get Planning </h2>
                    <p className="para-text mb-5">Fill in the form below and let’s begin planning your client’s next Maldivian getaway  </p>
                  </div>
              
              </div>
            

               <form className="mt-5-form">
          <div className="row input-style">
            <div className="col-12">
            <p className="para-form"> Full Name:</p>
            <input type="text"  autoComplete="off" name="fldCustomerFName" onChange={FnameChange} />
            <div className="error-waper">
            <div className="erroe-msg">{FNameArray[0]}</div>
          </div>
          </div>
            <div className="col-12">
            <p className="para-form"> Travel Agency / Tour Operator Name</p>
            <input type="text"  autoComplete="off" name="fldCustomerLName" onChange={LnameChange} />
            <div className="error-waper">
            <div className="erroe-msg">{LNameArray[0]}</div>
          </div>
          </div>
          </div>

            
          <div className="row input-style">
            <div className="col-12">
            <p className="para-form"> Country Of Origin </p>
            <select className="width-100 outline-none"
              labelId="country_id"
              id="country_id"
              defaultValue={""}
              onChange={handleCountryPname}
              name="fldCustomerFormResort1ID"
            >

              {countryNameMap.map(item=> (
                <option value={item.fldCountryName}>{item.fldCountryName}</option>
              ))}
            </select>
            </div>
            <div className="col-12">
            <p className="para-form"> Email:</p>
            <input type="text"  name="fldCustomerEmail" onChange={emailChange}/>
            <div className="error-waper">
            <div className="erroe-msg">{emailArray[0]}</div>
            </div>
            </div>
          </div>

            <div className="row input-style">
            <div className="col-12">
            <p className="para-form"> Company Number </p>
            <input type="text" name="fldCustomerPNumber" onChange={phoneChange}/>
            <div className="error-waper">
            <div className="erroe-msg">{phoneArray[0]}</div>
            </div>
            </div>
            <div className="col-12">
            <p className="para-form"> Whatsapp Number </p>
            <input type="text" name="fldCustomerPNumber" onChange={whatsappChange}/>
            <div className="error-waper">
            <div className="erroe-msg">{whatsappArray[0]}</div>
          </div>
            </div>
            
          </div>

          <div className="row input-style">
          
            <div className="col-12 plr-3">
            <p className="para-form mb-20">Number Of Pax</p>
            <div className="flex-align-center">
            <select 
                            className="nightSelect mr-right-form outline-none "
                              labelId="adults"
                              id="adults"
                              value={adults}
                              name="fldCustomerFormadults"
                              onChange={handleChangeadults}
                            >
                                <option  value={"2 Adults"}>1 Adults</option>
                                <option  value={"2 Adults"}>2 Adults</option>
                                <option value={"3 Adults"}>3 Adults</option> 
                                <option value={"4 Adults"}>4 Adults</option>
                                <option value={"5 Adults"}>5 Adults</option>
                                <option value={"6 Adults"}>6 Adults</option>
                                <option value={"7 Adults"}>7 Adults</option>
                                <option value={"8 Adults"}>8 Adults</option> 
                                <option value={"9 Adults"}>9 Adults</option>
                                <option value={"10 Adults"}>10 Adults</option>
                            </select>

                           <select 
                            className="nightSelect outline-none"
                              labelId="adults"
                              id="adults"
                              value={children}
                              name="fldCustomerFormadults"
                              onChange={handleChangechildren}
                            >
                                <option value={"0 children"}>0 Children</option>
                                <option value={"1 children"}>1 Child</option>
                                <option value={"2 children"}>2 Children</option> 
                                <option value={"3 children"}>3 Children</option>
                                <option value={"4 children"}>4 Children</option>
                            </select>
            </div>
                           
            </div>
            <div className="col-12">
            <p className="para-form mb-20">Mice Or Leisure Travel?</p>
              <div>
              <FormGroup className="display-inline-row">
               <FormControlLabel className="form-checkpoint" onChange={checkBoxTypeOfHoliday} control={<Checkbox  name="fldCustomerFormTypeOFMice" value="Mice" />} label="Mice"/>
                <FormControlLabel className="form-checkpoint" onChange={checkBoxTypeOfHoliday} control={<Checkbox name="fldCustomerFormTypeOFLeisureTravel" value="Leisure Travel"  /> } label="Leisure Travel"/>
            </FormGroup>
              </div>
            
         
            </div>
          
          </div>
          <div className="row input-style mt-50">
          <button className="submit-btn" type="submit" value="Submit" onClick={formSubmitHandler} >Submit</button>
          </div>
          
        </form>  
            </SlidingPane>

    </div>
      </li>

      <li className="bottom-menu bottom-menu-item">
               
               
                
      </li>

    

        <li className="email font-small m-header-top">
            <a href="mailto:info@viluxurholidays.com">info@viluxurholidays.com</a>
        </li>
        <li className="phone font-small">
            <a href="tel:0112372888" >0112 372 888</a>
        </li>
    </ul>
  </div>
</nav>

        </div>

    
      </div>
  </div>
</motion.div>
</motion.div>
}


    </div>

    


  );
};

export default Header;
